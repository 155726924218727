import { IEmail, IOnboardingResponse, IResponse, IResponseStages, ISendFile, ISetOnboarding, IVacantDescription } from "utils/interfaces";

import { talentoApi } from "./index";

const trackingExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getStages: builder.query<IResponseStages, IEmail>({
      query({ email }) {
        return {
          url: `/tracking/contacts/vacant?email=${email}`,
        };
      },
    }),

    sendFile: builder.mutation<IResponse, ISendFile>({
      query: ({
        data,
        email,
        idStage,
        idVacant,
        idActivity,
        activityName,
      }) => ({
        url: `/tracking/upload/file?email=${email}&idStage=${idStage}&idVacant=${idVacant}&idActivity=${idActivity}&activityName=${activityName}`,
        method: 'POST',
        body: data,
      }),
    }),

    getPreStageUser: builder.query<any, any>({
      query: (email) => ({
        url: `/tracking/contacts/vacant?email=${email}`,
      }),
    }),

    getVacantDescription: builder.query<IVacantDescription, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/tracking/contacts/vacant/description?email=${email}`,
        };
      },
    }),

    getOnboarding: builder.query<IOnboardingResponse, string>({
      query: (email) => `/tracking/onboarding/${email}`,
      keepUnusedDataFor: 0,
    }),

    setOnboarding: builder.mutation<any, ISetOnboarding>({
      query: ({ email, dashboard, status }) => ({
        url: `/tracking/onboarding/${email}/${dashboard}/${status}`,
        method: 'PATCH',
      })
    })
  }),
});

export const {
  useGetStagesQuery,
  useSendFileMutation,
  useGetPreStageUserQuery,
  useGetVacantDescriptionQuery,
  useGetOnboardingQuery,
  useSetOnboardingMutation
} = trackingExtensionApi;