import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import FormTab from 'components/atoms/FormTab/FormTab';
import './TabPanel.scss';
import { AButton, AImage } from 'components/atoms';
import { plus } from 'utils/assets';

import cn from 'classnames';

const TabPanelContext = createContext({
  activeTab: 0,
  setActiveTab: (id: number) => { },
  isLocked: false
});

export function useTabPanelContext() {
  return useContext(TabPanelContext);
}

function useActiveTabs(initialTab = 0, externalTabShow?: number) {
  const [activeTab, setActiveTab] = useState(initialTab);

  
  useEffect(() => {
    if (typeof externalTabShow === 'number') {
      setActiveTab(externalTabShow);
    }
  }, [externalTabShow]);

  const setActiveTabWithinBounds = (id: number) => {
    if (id < 0) {
      setActiveTab(0);
    } else {
      setActiveTab(id);
    }
  };

  return {
    activeTab,
    setActiveTab: setActiveTabWithinBounds,
  };
}

function TabPanelProvider({
  children,
  initialTab = 0,
  tabShow,
  isLocked = false,
}: Readonly<{ children?: React.ReactNode; initialTab?: number; tabShow?: number; isLocked?: boolean; }>) {
  const { activeTab, setActiveTab } = useActiveTabs(initialTab, tabShow);
  const value = useMemo(
    () => ({ activeTab, setActiveTab, isLocked }),
    [activeTab, setActiveTab, isLocked]
  );

  return (
    <TabPanelContext.Provider value={value}>
      {children}
    </TabPanelContext.Provider>
  );
}

export function TabPanel({
  children,
  initialTab = 0,
  tabShow,
  isLocked = false,
}: Readonly<{ children?: React.ReactNode; initialTab?: number; tabShow?: number; isLocked?: boolean; }>) {
  return (
    <TabPanelProvider initialTab={initialTab} tabShow={tabShow} isLocked={isLocked}>
      <div className="TabPanel">{children}</div>
    </TabPanelProvider>
  );
}

export function TabList({
  children,
}: Readonly<{ children?: React.ReactNode }>) {
  return <div className="TabPanel__tab-list">{children}</div>;
}

interface TabProps {
  /**
   * The id of the tab. Has to be unique.
   */
  id: number;
  children?: React.ReactNode;
  state?: 'success' | 'error' | 'none';
  disabled?: boolean;
  onClick?: (id: number | string) => void;
}

export function Tab({
  id,
  children,
  state,
  disabled,
  onClick,
}: Readonly<TabProps>) {
  const { activeTab, setActiveTab, isLocked } = useTabPanelContext();

  function handleTabClick() {
    if (!disabled && !isLocked) {
      setActiveTab(id);
      if (onClick) {
        onClick(id);
      }
    }
  }

  return (
    <FormTab
      selected={activeTab === id}
      status={state}
      onClick={handleTabClick}
      disabled={disabled}
      locked={isLocked}
    >
      {children}
      {activeTab === id && <div className="tab__border-bottom"></div>}
    </FormTab>
  );
}

/**
 * Props for the Section component.
 */
interface SectionProps {
  id: number;
  children?: React.ReactNode;
  /**
   * The previous button. If not provided, the previous button will not be rendered.
   * Has to be a TabPrevious component.
   * @example
   * <TabPrevious prev={1} />
   */
  next?: React.ReactElement<TabNextProps>;
  /**
   * The next button. If not provided, the next button will not be rendered.
   * Has to be a TabNext component.
   * @example
   * <TabNext next={2} />
   */
  prev?: React.ReactElement<TabPreviousProps>;
}

export function Section({ id, children, prev, next }: Readonly<SectionProps>) {
  const { activeTab } = useTabPanelContext();

  if (activeTab !== id) return <></>;

  return (
    <>
      <section role="sectionTab" className="TabPanel__section">
        {children}
      </section>
      <div className="TabPanel__section-buttons">
        <div className="TabPanel__section-buttons__prev">{prev}</div>
        <div className="TabPanel__section-buttons__next">{next}</div>
      </div>
    </>
  );
}

interface TabPreviousProps {
  prev: number;
  disabled?: boolean;
  onPrevious?: () => void;
}

export function TabPrevious({
  prev,
  disabled,
  onPrevious,
}: Readonly<TabPreviousProps>) {
  const { setActiveTab } = useTabPanelContext();

  function onClick() {
    if (!disabled) {
      if (onPrevious) onPrevious();
      setActiveTab(prev);
    }
  }

  return (
    <AButton
      tag="button"
      type="button"
      className="button-secondary"
      disabled={disabled}
      handleClick={onClick}
    >
      <span>Atrás</span>
    </AButton>
  );
}

interface TabNextProps {
  next: number;
  disabled?: boolean;
  onNext?: () => void;
}

export function TabNext({ next, disabled, onNext }: Readonly<TabNextProps>) {
  const { activeTab, setActiveTab } = useTabPanelContext();

  function onClick() {
    if (!disabled) {
      if (onNext) onNext();
      setActiveTab(next);
    }
  }

  return (
    <AButton
      tag="button"
      type="button"
      className="button-primary"
      disabled={disabled}
      handleClick={onClick}
    >
      <span>Siguiente</span>
    </AButton>
  );
}

export function Group({
  children,
  title,
  question,
  description,
  fullWidth = false,
}: Readonly<{
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  fullWidth?: boolean;
  question?: string;
  description?: string | React.ReactNode;
}>) {
  const groupContentClasses = cn('group__content', {
    'group__content--full-width': fullWidth,
  });

  return (
    <div className="group" role='group' data-testid="group-container">
      {title && <h3 className="group__title">{title}</h3>}
      {question && <p className="group__question">{question}</p>}
      {description && <p className="group__description">{description}</p>}
      <div className={groupContentClasses}>{children}</div>
    </div>
  );
}

export function Multiple({
  children,
  onButtonClick,
}: Readonly<{ children?: React.ReactNode; onButtonClick?: () => void }>) {
  return (
    <div className="Multiple">
      {children}
      <div className="multiple__button" onClick={onButtonClick}>
        <AImage url={plus} alt="Plus icon" />
      </div>
    </div>
  );
}
