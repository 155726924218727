import { IAButton } from 'utils/interfaces/store/activities.interfaces';
import './AButton.scss';
import { Loader } from '../Loader/Loader.components';

export const AButton = ({
  tag,
  type,
  rel,
  target,
  title,
  url,
  className,
  disabled,
  children,
  handleClick,
  role,
  style,
  dataTestId,
  isLoading,
}: IAButton) => {

  if (tag === 'a') {
    return (
      <a
        data-testid={dataTestId}
        type={type}
        rel={rel}
        href={!disabled ? url : undefined}
        target={!disabled ? target : undefined}
        onClick={!disabled ? handleClick : () => { }}
        className={`${className}${disabled && className?.includes('button-primary') ? ' disabled-primary' : disabled && className?.includes('button-secondary') ? ' disabled-secondary' : disabled && className?.includes('button-tertiary') ? ' disabled-tertiary' : ''}`}
      >
        {isLoading ? <Loader /> : (!!children ? children : title)}
      </a>
    );
  } else if (tag === 'button') {
    return (
      <button
        className={`${className}${disabled && className?.includes('button-primary') ? ' disabled-primary' : disabled && className?.includes('button-secondary') ? ' disabled-secondary' : disabled && className?.includes('button-third') ? ' disabled-secondary' : disabled && className?.includes('button-tertiary') ? ' disabled-tertiary' : ''}`}
        type={type}
        disabled={disabled}
        onClick={handleClick}
        role={role}
        style={style}
        data-testid={dataTestId}
      >
        {isLoading ? <Loader /> : (!!children ? children : title)}
      </button>
    );
  } else {
    return null;
  }
};
